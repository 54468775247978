const actions = {
  RUN_PLAYER_EFFECT: 'RUN_PLAYER_EFFECT'
};

/**
 * runPlayerEffect(...params) - Make player effects when some action is caused.
 *
 * @param {String} iconName
 * @param {String} animationName. See https://github.com/miniMAC/magic
 */

const runPlayerEffect = (iconName, animationName) => ({
  type: actions.RUN_PLAYER_EFFECT,
  payload: { iconName, animationName, runTime: new Date().getTime() }
});

const actionCreators = {
  runPlayerEffect
};

export { actions, actionCreators };
