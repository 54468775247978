const LOCAL_STORAGE_NAMESPACE = 'ITL.Components';

const LOCAL_STORAGE_KEY_STORAGE_VERSION = `${LOCAL_STORAGE_NAMESPACE}.StorageVersion`;
const LOCAL_STORAGE_KEY_MEDIA_PLAYER_SETTINGS = `${LOCAL_STORAGE_NAMESPACE}.MediaPlayerSettings`;
const LOCAL_STORAGE_KEY_MEDIA_TEXT_SETTINGS = `${LOCAL_STORAGE_NAMESPACE}.MediaTextSettings`;

export {
  LOCAL_STORAGE_NAMESPACE,

  LOCAL_STORAGE_KEY_STORAGE_VERSION,
  LOCAL_STORAGE_KEY_MEDIA_PLAYER_SETTINGS,
  LOCAL_STORAGE_KEY_MEDIA_TEXT_SETTINGS
};
