import { values, includes } from 'lodash';
import { ACTION_TYPES } from './actions';
import { CONTENT_TYPES } from './constants';

const INITIAL_STATE = {
  isOpen: false,
  contentType: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.OPEN: {
      const contentType = action.payload;
      if (!includes(values(CONTENT_TYPES), contentType)) {
        throw new TypeError(`Unknown HelpPopup contentType=${contentType}`);
      }
      return {
        ...state,
        isOpen: true,
        contentType
      };
    }
    case ACTION_TYPES.CLOSE: return {
      ...state,
      isOpen: false
    };
  }
  return state;
};

export default reducer;
