import { findKey } from 'lodash';

const CONTENT_TYPES = {
  BIGDEF_EMPTY_CLICK: 'bigdef_empty_click',
  RANDOM_ACCESS_UNUSED: 'random_access_unused',
};

const VIDEO_FILE_URLS = {
  [CONTENT_TYPES.BIGDEF_EMPTY_CLICK]: '/help_videos/click_open_bigdef.mp4',
  [CONTENT_TYPES.RANDOM_ACCESS_UNUSED]: '/help_videos/random_access.mp4'
};

const getStorageKeyNeverShowAgain = (contentType) => {
  const contentTypeName = findKey(CONTENT_TYPES, (val) => val === contentType);
  return `HelpPopup.NeverShowAgain.${contentTypeName}`;
};

export {
  CONTENT_TYPES,
  VIDEO_FILE_URLS,
  getStorageKeyNeverShowAgain
};
