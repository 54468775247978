const ACTION_TYPE_PREFIX = 'SETTINGS';

const ACTION_TYPES = {
  SET_SECTION: `${ACTION_TYPE_PREFIX}_SET_SECTION`
};

const setSection = (sectionName) => ({
  type: ACTION_TYPES.SET_SECTION,
  payload: sectionName
});

const settingsActions = {
  setSection
};

export { ACTION_TYPES, settingsActions };
