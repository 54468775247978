import { put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, actionCreators } from '../actions';

function CurrentUserFetcher({ authenticationApi }) {
  const { setCurrentUser } = actionCreators;

  function* fetchCurrentUser() {
    const currentUser = yield authenticationApi.fetchCurrentUser();
    yield put(setCurrentUser(currentUser));
  }

  return function* () {
    yield takeEvery([ACTION_TYPES.FETCH_CURRENT_USER_SET], fetchCurrentUser);
  };
}

export {
  CurrentUserFetcher
};
