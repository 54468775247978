import { find } from 'lodash';
import { findCurrentWord } from './text/selectors';

const FONT_SIZES = [10, 14, 16, 18, 20, 22, 24, 28, 32];

const findCurrentFragment = ({ mediaText, mediaPlayer }) => {
  const currentWord = findCurrentWord({ mediaText, mediaPlayer });
  return currentWord && currentWord.fragment;
};

const findCurrentRowIndex = ({ mediaText, mediaPlayer }) => {
  const currentWord = findCurrentWord({ mediaText, mediaPlayer });
  return currentWord ? currentWord.row_index : 0;
};

const findTargetTranslationLanguage = (languages, languageCode) => {
  if (languageCode) {
    const language = find(languages, { code: languageCode });
    if (language) {
      return language;
    }
  }

  return null;
};

export { FONT_SIZES, findCurrentFragment, findCurrentRowIndex, findTargetTranslationLanguage };
