import { ACTIONS_PREFIX } from '../../constants';

const NAMESPACE = 'MEDIA_PLAYER_PLAYER';

const actions = {
  TOGGLE: `${ACTIONS_PREFIX}_${NAMESPACE}_TOGGLE`,
  PLAY: `${ACTIONS_PREFIX}_${NAMESPACE}_PLAY`,
  PAUSE: `${ACTIONS_PREFIX}_${NAMESPACE}_PAUSE`,
  SET_PLAYED_TIME: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_PLAYED_TIME`,
  SET_DURATION: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_DURATION`,
  SET_IS_BUFFERING: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_IS_BUFFERING`,
  SET_IS_CHANGING_VOLUME: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_IS_CHANGING_VOLUME`,
  SET_VOLUME: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_VOLUME`,
  SET_PLAYBACK_RATE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_PLAYBACK_RATE`,
  SET_MEDIA_QUALITY: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_MEDIA_QUALITY`,
  SET_ONLY_AUDIO: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_ONLY_AUDIO`,
  SET_HOVERED: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_HOVERED`,
  SET_REWIND: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_REWIND`
};

const toggle = () => ({
  type: actions.TOGGLE
});

const play = () => ({
  type: actions.PLAY
});

const pause = () => ({
  type: actions.PAUSE
});

const setPlayedTime = (milliseconds) => ({
  type: actions.SET_PLAYED_TIME,
  payload: milliseconds
});

const setDuration = (milliseconds) => ({
  type: actions.SET_DURATION,
  payload: milliseconds
});

const setIsBuffering = (isBuffering) => ({
  type: actions.SET_IS_BUFFERING,
  payload: isBuffering
});

const setIsChangingVolume = (isChanging) => ({
  type: actions.SET_IS_CHANGING_VOLUME,
  payload: isChanging
});

const setVolume = (volume) => ({
  type: actions.SET_VOLUME,
  payload: volume
});

const setPlaybackRate = (playbackRate) => ({
  type: actions.SET_PLAYBACK_RATE,
  payload: playbackRate
});

const setMediaQuality = (mediaQuality) => ({
  type: actions.SET_MEDIA_QUALITY,
  payload: mediaQuality
});

const setOnlyAudio = (onlyAudio) => ({
  type: actions.SET_ONLY_AUDIO,
  payload: onlyAudio
});

const setHovered = (hovered) => ({
  type: actions.SET_HOVERED,
  payload: hovered
});

const setRewind = (rewind) => ({
  type: actions.SET_REWIND,
  payload: rewind
});

const actionCreators = {
  toggle,
  play,
  pause,
  setPlayedTime,
  setDuration,
  setIsBuffering,
  setIsChangingVolume,
  setVolume,
  setPlaybackRate,
  setMediaQuality,
  setOnlyAudio,
  setHovered,
  setRewind
};

export { actions, actionCreators };
