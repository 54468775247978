import { actions } from './actions';

const initialState = {
  open: false,
  header: '',
  content: '',
  additionalProps: {}
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_DIALOG:
      return {
        ...state,
        open: true,
        header: action.payload.header,
        content: action.payload.content,
        additionalProps: action.payload.additionalProps
      };
    case actions.DISMISS_DIALOG:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default dialog;
