import { ACTIONS_PREFIX } from '../constants';

const NAMESPACE = 'MEDIA_TEXT';

const actions = {
  SET_FETCHING_SYNC_DATA: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_FETCHING_SYNC_DATA`,
  SET_SYNC_DATA: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SYNC_DATA`,
  SET_TRANSLATION_LANGUAGES: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_TRANSLATION_LANGUAGES`,
  SET_AUTOSCROLL: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_AUTOSCROLL`,
  DRAGGED_WORD_SET: `${ACTIONS_PREFIX}_${NAMESPACE}_DRAGGED_WORD_SET`,
  SET_TARGET_TRANSLATION_LANGUAGE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_TARGET_TRANSLATION_LANGUAGE`,
  SET_GROUPED_WORD_TRANSLATIONS: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_GROUPED_WORD_TRANSLATIONS`,
  TOGGLE_AUTOSCROLL: `${ACTIONS_PREFIX}_${NAMESPACE}_TOGGLE_AUTOSCROLL`
};

const setSyncData = (data) => ({
  type: actions.SET_SYNC_DATA,
  payload: data
});

const setFetchingSyncData = (fetching) => ({
  type: actions.SET_FETCHING_SYNC_DATA,
  payload: fetching
});

const setGroupedWordTranslations = (translaions) => ({
  type: actions.SET_GROUPED_WORD_TRANSLATIONS,
  payload: translaions
});

const setTranslationLanguages = (translationLanguages) => ({
  type: actions.SET_TRANSLATION_LANGUAGES,
  payload: translationLanguages
});

const setAutoscroll = (value) => ({
  type: actions.SET_AUTOSCROLL,
  payload: value
});

const toggleAutoScroll = () => ({
  type: actions.TOGGLE_AUTOSCROLL
});

const dragWord = (word) => ({
  type: actions.DRAGGED_WORD_SET,
  payload: word
});

const setTargetTranslationLanguage = (language) => ({
  type: actions.SET_TARGET_TRANSLATION_LANGUAGE,
  payload: language
});

const actionCreators = {
  setSyncData,
  setFetchingSyncData,
  setGroupedWordTranslations,
  setTranslationLanguages,
  setTargetTranslationLanguage,
  setAutoscroll,
  toggleAutoScroll,
  dragWord
};

export { actions, actionCreators };
