import { takeEvery, put } from 'redux-saga/effects';
import { actionCreators, ACTION_TYPES } from '../actions';
import { isServerError } from '../../../shared/sagas/effects/request';

function KnownWordsFetcher({ bigDefApi }) {
  const { setKnownWords } = actionCreators;

  function* fetchKnownWords(action) {
    const currentUser = action.payload || {};

    try {
      const knownWords = currentUser.id ? yield bigDefApi.knownDictionaryWordIds() : [];
      yield put(setKnownWords(knownWords));
    } catch (error) {
      isServerError(error);
    }
  }

  return function* () {
    yield takeEvery([ACTION_TYPES.CURRENT_USER_SET], fetchKnownWords);
  };
}

export {
  KnownWordsFetcher
};
