import { takeEvery, put } from 'redux-saga/effects';
import { actionCreators, ACTION_TYPES } from '../actions';
import { isServerError } from '../../../shared/sagas/effects/request';

function UserWordsFetcher({ bigDefApi }) {
  const { setWordListWords } = actionCreators;

  function* fetchUserWords(action) {
    const currentUser = action.payload || {};

    try {
      const wordListWords = currentUser.id ? yield bigDefApi.wordListDictionaryWordIds() : [];
      yield put(setWordListWords(wordListWords));
    } catch (error) {
      isServerError(error);
    }
  }

  return function* () {
    yield takeEvery([ACTION_TYPES.CURRENT_USER_SET], fetchUserWords);
  };
}

export {
  UserWordsFetcher
};
