// TODO remove it?
import { actionTypes } from './actions';

const initialState = {
  loginWindowType: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_LOGIN_WINDOW:
      return {
        ...state,
        loginWindowType: action.payload
      };
  }
  return state;
};
