const actions = {
  BIGDEF_SET_WORD: 'BIGDEF_SET_WORD',
  BIGDEF_SET_WORD_INFO: 'BIGDEF_SET_WORD_INFO',
  BIGDEF_SET_IS_FETCHING: 'BIGDEF_SET_IS_FETCHING',
  BIGDEF_SET_MWE_ANCHOR: 'BIGDEF_SET_MWE_ANCHOR'
};

const setWord = (wordText) => ({
  type: actions.BIGDEF_SET_WORD,
  payload: wordText
});

const setWordInfo = (wordInfo) => ({
  type: actions.BIGDEF_SET_WORD_INFO,
  payload: wordInfo
});

const setMWEAnchor = (mweAnchor) => ({
  type: actions.BIGDEF_SET_MWE_ANCHOR,
  payload: mweAnchor
});

const setIsFetching = (isFetching) => ({
  type: actions.BIGDEF_SET_IS_FETCHING,
  payload: isFetching
});

const actionCreators = {
  setWord,
  setWordInfo,
  setIsFetching,
  setMWEAnchor
};

const initialState = {
  word: {},
  wordInfo: null,
  isFetching: false,
  mweAnchor: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.BIGDEF_SET_WORD:
      return {
        ...state,
        word: action.payload,
        mweAnchor: null
      };
    case actions.BIGDEF_SET_WORD_INFO: {
      const wordInfo = action.payload;
      return {
        ...state,
        wordInfo
      };
    }
    case actions.BIGDEF_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case actions.BIGDEF_SET_MWE_ANCHOR:
      return {
        ...state,
        mweAnchor: action.payload
      };
  }
  return state;
};

export default reducer;

export {
  actions,
  actionCreators
};
