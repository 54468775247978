import screenfull from 'screenfull';
import { tap } from 'lodash';
import { ACTION_TYPES as LAYOUT_ACTION_TYPES, actionCreators as layoutActions } from '../actions';

const FullscreenMiddleware = () => (next) => {
  screenfull.on('change', () => {
    next(layoutActions.setIsFullscreen(screenfull.isFullscreen));
  });

  return (action) => tap(next(action), () => {
    if (action.type === LAYOUT_ACTION_TYPES.START_FULLSCREEN) {
      screenfull.toggle(document.body);
    }
  });
};

export default FullscreenMiddleware;
