import Axios from 'axios';
import qs from 'qs';

export default (config = {}) => {
  const axios = Axios.create(config);

  // reading the token should happen closer to the app entry point
  const token = document.querySelector('meta[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-Token'] = token;

  axios.defaults.headers.common['Accept'] = 'application/json';

  axios.defaults.paramsSerializer = (params) => (
    qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  );

  return axios;
};
