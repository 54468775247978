import cookies from 'js-cookie';
import AirbrakeClient from 'airbrake-js';

import { forOwn } from "lodash";

const isEmptyString = (str) => (!str || str.trim().length === 0);

/**
 * Splits the given string into an array of words.
 *
 * @param {String} text
 * @returns {Array<String>}
 */

const splitIntoWords = (text) => text.split(/\s+/);

/**
 * Returns the width of the given string, by creating a span element,
 * adding it to the DOM, measuring it, and then removing it.
 *
 * @param {String} text The word to measure.
 * @return {Number} The width of the word, in pixels.
 */

const getTextWidth = (text, className) => {
  const span = document.createElement('span');
  const textNode = document.createTextNode(text);
  span.appendChild(textNode);

  span.className = className;
  document.body.appendChild(span);

  const width = span.offsetWidth;

  span.remove();

  return width;
};

const zeroPad = (number) => (number < 10 ? `0${number}` : number);

/**
 * Returns a formatted time string.
 *
 * @param {Number} time The time to be formatted, in seconds.
 * @returns {String}
 */

const formatTime = (time) => {
  const result = [];

  const seconds = Math.floor(time);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    result.push(hours);
  }

  result.push(minutes % 60);
  result.push(seconds % 60);

  return result.map(zeroPad).join(':');
};

const getStylePropertyValue = (node, property) => (
  window.getComputedStyle(node).getPropertyValue(property)
);

const fetchCookies = (key, defaultValue = {}) => {
  const value = cookies.getJSON(`_itl-${key}`);
  return value === undefined ? defaultValue : value;
};

const removeCookies = (key, params = {}) => {
  cookies.remove(`_itl-${key}`, params);
};

const setCookies = (key, value, expires = 365, withCurrentPath = true, domain = null, additionalParams = {}) => {
  const params = { expires, path: withCurrentPath ? window.location.pathname : '/', ...additionalParams };
  if (domain) {
    params.domain = domain;
  }
  cookies.set(`_itl-${key}`, value, params);
};

const setUpAirbrake = () => {
  if (process.env.AIRBRAKE_HOST) {
    const airbrake = new AirbrakeClient({
      host: process.env.AIRBRAKE_HOST,
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_PROJECT_KEY,
      environment: process.env.ENVIRONMENT
    });

    airbrake.addFilter((notice) => {
      if (notice.context.environment === 'production') {
        return notice;
      }
      return null;
    });
  }
};

/**
 * Get string human time by seconds
 *
 * @param {Number} seconds
 * @returns {String} of human time
 * @example:
 *   secondsToStrfTime(308.175238) => 05:08
 */
const secondsToStrfTime = (seconds) => {
  const sec = Math.floor(seconds);
  const hms = [Math.floor(sec / 3600) % 24,
    Math.floor(sec / 60) % 60,
    sec % 60];

  return hms
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

/**
 * round decimal number
 *
 * @param {Number} value
 * @param {Number} precision
 *
 * @returns {Number} rounded value
 * @example:
 *   roundDecimal(0.30000000000000004) => 0.3
 */
const roundDecimal = (value, precision = 1) => Number(`${Math.round(`${value}e${precision}`)}e-${precision}`);

// TODO: maybe we need endpoint on server to make fragments by sync instead of to do it on client
// group sync by fragments
//
// Example:
// sync:
//    [
//      {time: 28.73469387755102, text: "text1", id: 0, fragment: 0}
//      {time: 28.878367346938777, text: "text2", id: 1, fragment: 0}
//      {time: 30.0237641723356, text: "text3", id: 2, fragment: 35}
//      {time: 30.459251700680273, text: "text4;", id: 3, fragment: 35}
//    ]
//
// fragments will be:
//     [
//       { 0: [
//              {time: 28.73469387755102, text: "text1", id: 0, fragment: 0},
//              {time: 28.878367346938777, text: "text2", id: 1, fragment: 0}
//            ],
//       },
//       { 35: [
//               {time: 28.73469387755102, text: "text3", id: 2, fragment: 35},
//               {time: 28.878367346938777, text: "text4", id: 3, fragment: 35}
//             ]
//       }
//     ]
const getFragmnentsFromSync = (sync) => {
  if (sync.length > 0) {
    let currentFragment = sync[0].fragment;

    const fragments = [];
    let index = 0;
    let words = [];

    sync.forEach((word) => {
      if (word.fragment !== currentFragment) {
        fragments[index] = { [currentFragment]: words };
        currentFragment = word.fragment;
        index += 1;
        words = [];
        words.push(word);
      }
      else {
        words.push(word);
      }
    });

    if (words.length > 0) {
      fragments[index] = { [currentFragment]: words };
    }

    return fragments;
  }

  return [];
};

/**
 * use to skip function <handleEvent> when target is an input field
 */
const skipInputFields = (handleEvent) => (e) => {
  const { target } = e;

  if (target.nodeName === 'INPUT' ||
    target.nodeName === 'TEXTAREA' ||
    target.isContentEditable
  ) {
    return;
  }

  handleEvent(e);
};

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const embedCode = ({ mediaItemId, mediaItemGroupId }) => {
  let src;

  if (mediaItemGroupId) {
    src = `${process.env.FULL_URL}/mediaitems/${mediaItemId}/embed?group_id=${mediaItemGroupId}`;
  } else {
    src = `${process.env.FULL_URL}/mediaitems/${mediaItemId}/embed`;
  }

  return (
    `<iframe src="${src}" width="100%" height="100%" frameBorder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`
  );
};

const extractValidationMessages = (error) => {
  const keys = [];

  const { errors } = error.response.data;

  if (errors) {
    forOwn(errors, (value, key) => {
      keys.push(`validations.errorMessages.errors.${key}.${value}`);
    });
  }

  return keys[0];
};

const profileUrl = (id) => `/profiles/${id}`;

const mediaItemUrl = (id) => `/media/${id}`;

const groupUrl = (id) => `/g/${id}`;

const embedMediaitem = document.getElementById('embed-mediaitem');
const embedInitStorage = JSON.parse(embedMediaitem?.dataset?.embedInitStore || '{}');

export {
  isEmptyString,
  roundDecimal,
  splitIntoWords,
  getTextWidth,
  formatTime,
  secondsToStrfTime,
  getStylePropertyValue,
  fetchCookies,
  setCookies,
  removeCookies,
  setUpAirbrake,
  getFragmnentsFromSync,
  skipInputFields,
  isValidHttpUrl,
  embedCode,
  extractValidationMessages,
  profileUrl,
  mediaItemUrl,
  groupUrl,
  embedInitStorage
};
