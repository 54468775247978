import { ACTION_TYPES, onboardingTourActions } from './actions';
import { actionCreators as mediaTextSettingActions } from '../../components/media-text/settings/actions';
import { actions } from '../../components/media-text/actions';
import { actionCreators as layoutActions } from '../../components/layout/actions';
import { SIDE_PANE_SECTIONS } from '../../components/layout/constants';

import { EXPLORE_WORD_INFO, EXPLORE_WORD_LISTS, STEP_NAMES } from './constants';

const OnboardingTourMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ACTION_TYPES.START) {
    dispatch(mediaTextSettingActions.setOnboardingTourMode());
  }
  if (action.type === ACTION_TYPES.FINISH) {
    setTimeout(() => {
      dispatch(mediaTextSettingActions.cancelOnboardingTourMode());
    }, 0);
  }

  if (action.type === ACTION_TYPES.SET_STEP_INDEX) {
    if (STEP_NAMES[action.payload] === EXPLORE_WORD_INFO) {
      dispatch(layoutActions.openSidePaneSection(SIDE_PANE_SECTIONS.BIG_DEF));
    }

    if (STEP_NAMES[action.payload] === EXPLORE_WORD_LISTS) {
      dispatch(layoutActions.openSidePaneSection(SIDE_PANE_SECTIONS.WORD_LISTS));
    }
  }

  if (action.type === actions.DRAGGED_WORD_SET && getState().onboardingTour.active) {
    dispatch(onboardingTourActions.setRun(!action.payload));
  }
};

export default OnboardingTourMiddleware;
