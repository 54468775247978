import { actions } from './actions';
import effect from './effect/reducer';
import playlist from './playlist/reducer';

import { PLAYBACK_RATE_DEFAULT, MEDIA_QUALITY_DEFAULT, REWIND_BETWEEN_FRAGMENTS } from './constants';

import { readSettingsFromStorage } from '../../store/settings';

const defaultState = {
  reactPlayer: null,
  volume: 0.5,
  playbackRate: PLAYBACK_RATE_DEFAULT,
  mediaQuality: MEDIA_QUALITY_DEFAULT,
  onlyAudio: false,
  playing: false,
  hovered: false,
  playedTime: 0,
  duration: 0,
  isBuffering: false,
  isChangingVolume: false,
  rewind: REWIND_BETWEEN_FRAGMENTS
};

const initialState = Object.assign({}, { ...defaultState }, { ...readSettingsFromStorage().mediaPlayer.player });

const player = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE:
      return { ...state, playing: !state.playing };
    case actions.PLAY:
      return { ...state, playing: true };
    case actions.PAUSE:
      return { ...state, playing: false };
    case actions.SET_PLAYED_TIME:
      return { ...state, playedTime: action.payload };
    case actions.SET_DURATION:
      return { ...state, duration: action.payload };
    case actions.SET_IS_BUFFERING:
      return { ...state, isBuffering: action.payload };
    case actions.SET_IS_CHANGING_VOLUME:
      return { ...state, isChangingVolume: action.payload };
    case actions.SET_VOLUME:
      return { ...state, volume: action.payload };
    case actions.SET_PLAYBACK_RATE:
      return { ...state, playbackRate: action.payload };
    case actions.SET_MEDIA_QUALITY:
      return { ...state, mediaQuality: action.payload };
    case actions.SET_ONLY_AUDIO:
      return { ...state, onlyAudio: action.payload };
    case actions.SET_HOVERED:
      return { ...state, hovered: action.payload };
    case actions.SET_REWIND:
      return { ...state, rewind: action.payload };
    default:
      return {
        ...state,
        effect: effect(state.effect, action),
        playlist: playlist(state.playlist, action)
      };
  }
};

export default player;
