import { actions } from './actions';

const initialState = {
  opened: false,
  group: null
};

const playlist = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN:
      return { ...state, opened: true };
    case actions.CLOSE:
      return { ...state, opened: false };
    case actions.SET_GROUP:
      return { ...state, group: action.payload };
    default:
      return state;
  }
};

export default playlist;
