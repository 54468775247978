import React, { PureComponent, createContext, memo, cloneElement } from 'react';
import EventEmitter from 'events';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { actionCreators as learnerDataActionCreators } from '../components/learner-data/actions';
import { actionCreators as layoutActionCreators } from '../components/layout/actions';

const { Provider, Consumer } = createContext();

const ExternalNavigation = ({ children }) => (
  <Consumer>
    {({ navigate }) => children({ navigate })}
  </Consumer>
);

const Link = memo(({ to, children }) => (
  <ExternalNavigation>
    {({ navigate }) => cloneElement(children, { onClick: () => navigate(to) })}
  </ExternalNavigation>
));

const withMessages = (Component) => (props) => (
  <Consumer>
    {({ messages, sendMessage }) => <Component messages={messages} sendMessage={sendMessage} {...props} />}
  </Consumer>
);

const mapDispatchToProps = {
  setCurrentUser: learnerDataActionCreators.setCurrentUser,
  setClientVersion: layoutActionCreators.setClientVersion
};

const withParentFrame = ({ events }) => (Component) => {
  class ParentFrame extends PureComponent {
    constructor(props) {
      super(props);
      this.messages = new EventEmitter();
    }

    navigate = (page) => {
      window.parent.postMessage({ type: 'navigate', payload: page }, '*');
    };

    requestUser = () => {
      window.parent.postMessage({ type: 'requestUser' }, '*');
    };

    sendMessage = (message) => window.parent.postMessage(message, '*');

    onMessage = (event) => {
      // TODO check for allowed origin (3ears.com, etc)
      const { data } = event;
      switch (data.type) {
        case 'login': {
          this.setCurrentUser(data.payload);
          break;
        }
        case 'logout': {
          this.setCurrentUser(null);
          break;
        }
        case 'changeUILanguage': {
          this.changeUILanguage(data.payload);
          break;
        }
        case 'clientVersion': {
          this.setClientVersion(data.payload);
          break;
        }
        default: {
          this.messages.emit(data.type, data.payload);
        }
      }
    };

    setCurrentUser(user) {
      const { setCurrentUser } = this.props;
      setCurrentUser(user);
    }

    changeUILanguage(languageCode) {
      const { i18n } = this.props;
      i18n.changeLanguage(languageCode);
    }

    setClientVersion(clientVersion) {
      const { setClientVersion } = this.props;
      setClientVersion(clientVersion);
    }

    componentDidMount() {
      window.addEventListener('message', this.onMessage);
      window.parent.postMessage({ type: 'initIFrame' }, '*');
      this.setHotjarEvents();
    }

    componentWillUnmount() {
      window.removeEventListener('message', this.onMessage);
      events.removeListener('login', this.login);
    }

    setHotjarEvents() {
      const queryParams = new URLSearchParams(window.location.search);
      const with_google_ads = queryParams.get('with_google_ads');
      if (with_google_ads) {
        hj('event', 'with_google_ads');
      }
    }

    render() {
      const { children, ...childProps } = this.props;
      const { requestUser } = this;

      // TODO maintain context as an immutable instance variable
      const context = {
        navigate: this.navigate,
        messages: this.messages,
        sendMessage: this.sendMessage
      };

      return (
        <Provider value={context}>
          <Component requestUser={requestUser} {...childProps} />
        </Provider>
      );
    }
  }

  return withTranslation()(
    connect(null, mapDispatchToProps)(ParentFrame)
  );
};

export default withParentFrame;

export { Link, withMessages };
