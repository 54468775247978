import { takeEvery, put } from 'redux-saga/effects';
import { ACTION_TYPES } from './actions';
import { actionCreators as playerActionCreators } from '../media-player/player/actions';

function* pauseMediaOnOpen({ meta }) {
  const action = playerActionCreators.pause();
  yield put({
    ...action,
    meta
  });
}

function* helpPopupSaga() {
  yield takeEvery(ACTION_TYPES.OPEN, pauseMediaOnOpen);
}

export default helpPopupSaga;
