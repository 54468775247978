import { ACTION_TYPES } from './actions';

const INITIAL_STATE = {
  isOpenModal: false
};

const suggestedWordList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.OPEN: {
      return {
        ...state, isOpenModal: true
      };
    }
    case ACTION_TYPES.CLOSE: {
      return {
        ...state, isOpenModal: false
      };
    }
    default:
      return state;
  }
};

export default suggestedWordList;
