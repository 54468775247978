import { actions } from './actions';
import { FONT_SIZES } from '../constants';
import { BACKGROUND_OPACITY_DEFAULT, VISIBLE_LINES_NUMBER_DEFAULT } from '../text/fragments/constants';
import { readSettingsFromStorage } from '../../store/settings';

const FONT_SIZE_MAX_VALUE = FONT_SIZES.length - 1;
const SPACE_BETWEEN_FRAGMENTS_MAX_VALUE = 20;
const SCROLL_TO_ALIGNMENTS = ['start', 'center', 'end'];
const FRAGMENT_TRANSLATIONS_LOCATIONS = ['top', 'betweenSentences', 'sideBySide'];

const defaultState = {
  fontSize: FONT_SIZE_MAX_VALUE / 2,
  translationFontSize: 3,
  spaceBetweenFragments: SPACE_BETWEEN_FRAGMENTS_MAX_VALUE / 2,
  visibleLinesNumber: VISIBLE_LINES_NUMBER_DEFAULT,
  showSubtitles: true,
  showFragmentTranslation: true,
  showWordTranslation: true,
  showKnownWords: true,
  showWordListWords: false,
  clickRandomAccessMode: true,
  scrollToAlignment: SCROLL_TO_ALIGNMENTS[1],
  fragmentTranslationsLocation: FRAGMENT_TRANSLATIONS_LOCATIONS[2],
  grammarHighlights: false,
  backgroundOpacity: BACKGROUND_OPACITY_DEFAULT
};

const initialState = Object.assign({}, { ...defaultState }, { ...readSettingsFromStorage().mediaText.settings });

const settings = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FONT_SIZE:
      return { ...state, fontSize: action.payload };
    case actions.SET_TRANSLATION_FONT_SIZE:
      return { ...state, translationFontSize: action.payload };
    case actions.SET_SCROLL_TO_ALIGNMENT:
      return { ...state, scrollToAlignment: action.payload };
    case actions.SET_SPACE_BETWEEN_FRAGMENTS:
      return { ...state, spaceBetweenFragments: action.payload };
    case actions.SET_VISIBLE_LINES_NUMBER:
      return { ...state, visibleLinesNumber: action.payload };
    case actions.SET_SHOW_SUBTITLES:
      return { ...state, showSubtitles: action.payload };
    case actions.TOGGLE_SHOW_SUBTITLES:
      return { ...state, showSubtitles: !state.showSubtitles };
    case actions.SET_SHOW_FRAGMENT_TRANSLATION:
      return { ...state, showFragmentTranslation: action.payload };
    case actions.TOGGLE_SHOW_FRAGMENT_TRANSLATION:
      return { ...state, showFragmentTranslation: !state.showFragmentTranslation };
    case actions.SET_SHOW_WORD_TRANSLATION:
      return { ...state, showWordTranslation: action.payload };
    case actions.SET_SHOW_KNOWN_WORDS:
      return { ...state, showKnownWords: action.payload };
    case actions.SET_SHOW_WORD_LIST_WORDS:
      return { ...state, showWordListWords: action.payload };
    case actions.SET_FRAGMENT_TRANSLATIONS_LOCATION:
      return { ...state, fragmentTranslationsLocation: action.payload };
    case actions.TOGGLE_SHOW_WORD_TRANSLATIONS:
      const { showWordTranslation } = state;
      return {
        ...state,
        showWordTranslation: !showWordTranslation
      };
    case actions.SET_CLICK_RANDOM_ACCESS_MODE:
      return { ...state, clickRandomAccessMode: action.payload };
    case actions.SET_GRAMMAR_HIGHLIGHTS:
      return { ...state, grammarHighlights: action.payload };
    case actions.SET_BACKGROUND_OPACITY:
      return { ...state, backgroundOpacity: action.payload };
    case actions.SET_ONBOARDING_TOUR_MODE:
      return {
        ...state,
        fontSize: FONT_SIZE_MAX_VALUE / 2,
        spaceBetweenFragments: SPACE_BETWEEN_FRAGMENTS_MAX_VALUE / 2,
        visibleLinesNumber: VISIBLE_LINES_NUMBER_DEFAULT,
        showSubtitles: true,
        showFragmentTranslation: true,
        showWordTranslation: false,
        showKnownWords: true,
        showWordListWords: false,
        clickRandomAccessMode: true,
        scrollToAlignment: SCROLL_TO_ALIGNMENTS[1],
        fragmentTranslationsLocation: FRAGMENT_TRANSLATIONS_LOCATIONS[0],
        grammarHighlights: false,
        backgroundOpacity: BACKGROUND_OPACITY_DEFAULT
      };
    case actions.CANCEL_ONBOARDING_TOUR_MODE:
      return { ...state, ...readSettingsFromStorage().mediaText.settings };
    default:
      return state;
  }
};

export default settings;

export {
  FONT_SIZE_MAX_VALUE,
  SPACE_BETWEEN_FRAGMENTS_MAX_VALUE,
  SCROLL_TO_ALIGNMENTS,
  FRAGMENT_TRANSLATIONS_LOCATIONS
};
