import { getDeviceOrientation } from 'alp-shared-components/dist/shared/utils';
import { SIDE_PANE_SECTIONS, MODES } from './constants';
import { ACTION_TYPES } from './actions';

const initialState = {
  sidePaneSection: SIDE_PANE_SECTIONS.BIG_DEF,
  isWordListsDropZoneHovered: false,
  isSettingsOpen: false,
  isSidePaneOpen: false,
  isSelectNativeLanguageOpen: false,
  isFullscreen: false,
  isBigdefPopupOpen: false,
  mode: MODES.LANDSCAPE,
  orientation: getDeviceOrientation(),
  clientVersion: null,
  flashcardsSessionType: false
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.OPEN_SIDE_PANE_SECTION: {
      const sidePaneSection = action.payload;
      if (!Object.values(SIDE_PANE_SECTIONS).includes(sidePaneSection)) {
        throw new TypeError(`unknown side pane section ${sidePaneSection}`);
      }
      return {
        ...state,
        sidePaneSection
      };
    }
    case ACTION_TYPES.SET_IS_WORD_LISTS_DROP_ZONE_HOVERED:
      return {
        ...state,
        isWordListsDropZoneHovered: action.payload
      };
    case ACTION_TYPES.OPEN_SETTINGS: {
      const isSettingsOpen = action.payload;
      return {
        ...state,
        isSettingsOpen
      };
    }
    case ACTION_TYPES.SET_IS_SIDE_PANE_OPEN: {
      const isSidePaneOpen = action.payload;
      return {
        ...state,
        isSidePaneOpen
      };
    }
    case ACTION_TYPES.SET_IS_FULLSCREEN: {
      const isFullscreen = action.payload;
      return {
        ...state,
        isFullscreen
      };
    }
    case ACTION_TYPES.SET_IS_SELECT_NATIVE_LANGUAGE_OPEN: {
      const isSelectNativeLanguageOpen = action.payload;
      return {
        ...state,
        isSelectNativeLanguageOpen
      };
    }
    case ACTION_TYPES.SET_IS_BIGDEF_POPUP_OPEN: {
      return {
        ...state,
        isBigdefPopupOpen: action.payload
      };
    }
    case ACTION_TYPES.SET_MODE: {
      return { ...state, mode: action.payload };
    }
    case ACTION_TYPES.SET_ORIENTATION: {
      return { ...state, orientation: action.payload };
    }
    case ACTION_TYPES.SET_CLIENT_VERSION: {
      return { ...state, clientVersion: action.payload };
    }
    case ACTION_TYPES.SET_FLASHCARDS_SESSION_TYPE: {
      return { ...state, flashcardsSessionType: action.payload };
    }
  }
  return state;
};

export default layoutReducer;
