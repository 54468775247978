const ACTION_TYPE_PREFIX = 'LAYOUT';

const ACTION_TYPES = {
  OPEN_SIDE_PANE_SECTION: `${ACTION_TYPE_PREFIX}_OPEN_SIDE_PANE_SECTION`,
  SET_IS_WORD_LISTS_DROP_ZONE_HOVERED: `${ACTION_TYPE_PREFIX}_SET_IS_WORD_LISTS_DROP_ZONE_HOVERED`,
  OPEN_SETTINGS: `${ACTION_TYPE_PREFIX}_OPEN_SETTINGS`,
  SET_IS_SIDE_PANE_OPEN: `${ACTION_TYPE_PREFIX}_SET_IS_SIDE_PANE_OPEN`,
  SET_IS_FULLSCREEN: `${ACTION_TYPE_PREFIX}_SET_IS_FULLSCREEN`,
  START_FULLSCREEN: `${ACTION_TYPE_PREFIX}_START_FULLSCREEN`,
  SET_MODE: `${ACTION_TYPE_PREFIX}_SET_MODE`,
  SET_ORIENTATION: `${ACTION_TYPE_PREFIX}_SET_ORIENTATION`,
  SET_IS_SELECT_NATIVE_LANGUAGE_OPEN: `${ACTION_TYPE_PREFIX}_SET_IS_SELECT_NATIVE_LANGUAGE_OPEN`,
  SET_IS_BIGDEF_POPUP_OPEN: `${ACTION_TYPE_PREFIX}_SET_IS_BIGDEF_POPUP_OPEN`,
  SET_CLIENT_VERSION: `${ACTION_TYPE_PREFIX}_SET_CLIENT_VERSION`,
  SET_FLASHCARDS_SESSION_TYPE: `${ACTION_TYPE_PREFIX}_SET_FLASHCARDS_SESSION_TYPE`
};

const openSidePaneSection = (sectionName) => ({
  type: ACTION_TYPES.OPEN_SIDE_PANE_SECTION,
  payload: sectionName
});

const setIsWordListsDropZoneHovered = (isHovered) => ({
  type: ACTION_TYPES.SET_IS_WORD_LISTS_DROP_ZONE_HOVERED,
  payload: isHovered
});

const openSettings = (isSettingsOpen) => ({
  type: ACTION_TYPES.OPEN_SETTINGS,
  payload: isSettingsOpen
});

const setIsSidePaneOpen = (isSidePaneOpen) => ({
  type: ACTION_TYPES.SET_IS_SIDE_PANE_OPEN,
  payload: isSidePaneOpen
});

const setIsFullscreen = (isFullscreen) => ({
  type: ACTION_TYPES.SET_IS_FULLSCREEN,
  payload: isFullscreen
});

const startFullscreen = () => ({
  type: ACTION_TYPES.START_FULLSCREEN
});

const setIsSelectNativeLanguageOpen = (isSelectNativeLanguageOpen) => ({
  type: ACTION_TYPES.SET_IS_SELECT_NATIVE_LANGUAGE_OPEN,
  payload: isSelectNativeLanguageOpen
});

const setIsBigdefPopupOpen = (isOpen) => ({
  type: ACTION_TYPES.SET_IS_BIGDEF_POPUP_OPEN,
  payload: isOpen
});

const setMode = (mode) => ({
  type: ACTION_TYPES.SET_MODE,
  payload: mode
});

const setOrientation = (orientation) => ({
  type: ACTION_TYPES.SET_ORIENTATION,
  payload: orientation
});

const setClientVersion = (clientVersion) => ({
  type: ACTION_TYPES.SET_CLIENT_VERSION,
  payload: clientVersion
});

const setFlashcardsSessionType = (sessionType) => ({
  type: ACTION_TYPES.SET_FLASHCARDS_SESSION_TYPE,
  payload: sessionType
});

const actionCreators = {
  openSidePaneSection,
  setIsWordListsDropZoneHovered,
  setIsSelectNativeLanguageOpen,
  openSettings,
  setIsSidePaneOpen,
  setIsFullscreen,
  startFullscreen,
  setIsBigdefPopupOpen,
  setMode,
  setOrientation,
  setClientVersion,
  setFlashcardsSessionType
};

export { ACTION_TYPES, actionCreators };
