const actions = {
  OPEN_DIALOG: 'OPEN_DIALOG',
  DISMISS_DIALOG: 'DISMISS_DIALOG'
};

const openDialog = (payload) => ({
  type: actions.OPEN_DIALOG, payload
});

const dismissDialog = () => ({
  type: actions.DISMISS_DIALOG
});

const actionCreators = {
  openDialog,
  dismissDialog
};

export { actions, actionCreators };
