import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { number, object } from 'prop-types';
import { Provider, connect } from 'react-redux';
import EventEmitter from 'events';
import { Trans, withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { TranslatorProvider } from 'alp-shared-components/dist/shared/providers/translator';
import AlertifyProvider from 'alp-shared-components/dist/shared/alertify/provider';
import { isMobile, isSafari } from 'alp-shared-components/dist/shared/utils';

import PublicAPI from '../src/api/public';
import publicApiUrl from '../src/api/public-api-url';

import i18n, { alpNameSpace } from '../src/ui/i18n';
import withParentFrame from '../src/embed-media-item/parent-frame';
import setupStore from '../src/embed-media-item/store';
import ProductInfo from '../src/components/paypal/product-info';

import '../src/embed-media-item/global.scss';
import css from '../src/components/paypal/styles.scss';
import { authenticationModalActions } from '../src/components/authentication-modal/actions';
import { Provider as AuthenticationApiProvider } from '../src/api/context-authentication';
import AuthenticationAPI from '../src/api/authentication';
import AuthenticationModalProvider from '../src/components/authentication-modal/provider';

i18n('components');

const publicApi = PublicAPI(publicApiUrl);
const authenticationApi = AuthenticationAPI(`${publicApiUrl}/client/users`);

const loginPage = document.getElementById('login-page');
const { returnTo } = loginPage.dataset;

const urlMaps = {
  mediaitems: 'media_items',
  groups: 'groups'
};

const [_a, productType, productId] = returnTo.split('/');
const parentFrameEventEmitter = new EventEmitter();
const store = setupStore({});
const mobile = isMobile();

const LoginPage = (props) => {
  const { openAuthenticationModal, currentUserId, classes, t } = props;

  const [product, setProduct] = useState({});

  const handleClick = () => {
    openAuthenticationModal('buyMediaContent');
  };

  useEffect(() => {
    if (currentUserId) {
      window.location.href = returnTo;
    }
  }, [currentUserId]);

  useEffect(() => {
    openAuthenticationModal('buyMediaContent');
  }, []);

  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = () => {
    publicApi.purchaseInformation(productId, urlMaps[productType]).then((data) => setProduct(data));
  };

  return (
    <TranslatorProvider ns={alpNameSpace} t={t} TransComponent={Trans}>
      <AlertifyProvider>
        <AuthenticationApiProvider value={authenticationApi}>
          <AuthenticationModalProvider>

            <div className={clsx(css.content, { [css.mobile]: mobile && isSafari })}>
              <ProductInfo product={product} />
              <Typography variant="h6" gutterBottom className={classes.root}>
                <Trans i18nKey="loginPage.title">
                  If you want to buy this content please
                  <Button onClick={handleClick} color="primary">Login</Button>
                </Trans>
              </Typography>
            </div>
          </AuthenticationModalProvider>
        </AuthenticationApiProvider>
      </AlertifyProvider>
    </TranslatorProvider>
  );
};

const LoginPageWithStyles = withStyles({
  root: {
    textAlign: 'center'
  }
})(withTranslation()(LoginPage));

LoginPage.propTypes = {
  currentUserId: number,
  classes: object.isRequired
};

LoginPage.defaultProps = {
  currentUserId: null
};

const mapStateToProps = ({ learnerData }) => (
  { currentUserId: learnerData.currentUser.id }
);

const mapDispatchToProps = {
  openAuthenticationModal: authenticationModalActions.openAuthenticationModal
};

const LoginPageWithParentFrame = withParentFrame({ events: parentFrameEventEmitter })(
  connect(mapStateToProps, mapDispatchToProps)(LoginPageWithStyles)
);

const root = createRoot(loginPage);

root.render(
  <Provider store={store}>
    <LoginPageWithParentFrame />
  </Provider>
);
