export const PLAYBACK_RATE_MIN = 0.5;
export const PLAYBACK_RATE_DEFAULT = 1.0;
export const PLAYBACK_RATE_MAX = 1.5;

export const MEDIA_QUALITY_LOW = 'small';
export const MEDIA_QUALITY_MEDIUM = 'medium';
export const MEDIA_QUALITY_HIGH = 'large';
export const MEDIA_QUALITY_DEFAULT = MEDIA_QUALITY_MEDIUM;

export const REWIND_FIVE_SECONDS = 'fiveSeconds';
export const REWIND_BETWEEN_FRAGMENTS = 'betweenFragments';
