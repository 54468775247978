import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import alpSharedComponentsLocales from 'alp-shared-components/dist/locales';

import loadPath from '../../loadLocalesPath.js.erb';

const alpNameSpace = 'sharedComponents';

export default (nameSpace) => {
  // const languageDetectorOptions = {
  //   lookupLocalStorage: `i18nextLng-${nameSpace}`
  // };

  i18n
    .use(Backend)
    // .use(new LanguageDetector(null, languageDetectorOptions))
    .use(initReactI18next)
    .init({
      backend: {
        loadPath
      },
      fallbackLng: 'en',
      load: 'currentOnly',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },

      ns: nameSpace,
      defaultNS: nameSpace,

      parseMissingKeyHandler: () => '',

      react: {
        useSuspense: false
      }
    });

  i18n.addResourceBundle('en', alpNameSpace, alpSharedComponentsLocales.en);
  i18n.addResourceBundle('ru', alpNameSpace, alpSharedComponentsLocales.ru);
  i18n.addResourceBundle('ar', alpNameSpace, alpSharedComponentsLocales.ar);
};

export { alpNameSpace };
