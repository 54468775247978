const VISIBLE_LINES_NUMBER_MIN = 0;
const VISIBLE_LINES_NUMBER_MAX = 4;
const VISIBLE_LINES_NUMBER_DEFAULT = 1;
const VISIBLE_LINES_NUMBER_RATIO = (n) => 2 * n + 1;

const BACKGROUND_OPACITY_MIN = 0;
const BACKGROUND_OPACITY_MAX = 10;
const BACKGROUND_OPACITY_DEFAULT = 4;

const VISIBLE_LINE_NUMBERS_TO_PERCENTAGES = {
  0: 10,
  1: 25,
  2: 50,
  3: 75,
  4: 100
};

export {
  VISIBLE_LINES_NUMBER_MIN,
  VISIBLE_LINES_NUMBER_MAX,
  VISIBLE_LINES_NUMBER_DEFAULT,
  VISIBLE_LINES_NUMBER_RATIO,
  VISIBLE_LINE_NUMBERS_TO_PERCENTAGES,
  BACKGROUND_OPACITY_MIN,
  BACKGROUND_OPACITY_MAX,
  BACKGROUND_OPACITY_DEFAULT
};
