import { values, isFunction, includes } from 'lodash';
import {
  LOCAL_STORAGE_KEY_MEDIA_PLAYER_SETTINGS,
  LOCAL_STORAGE_KEY_MEDIA_TEXT_SETTINGS
} from '../storage/constants';
import { actions as PLAYER_ACTION_TYPES } from '../media-player/player/actions';
import { actions as MEDIA_TEXT_SETTINGS_ACTION_TYPES } from '../media-text/settings/actions';

const RELATED_ACTION_TYPES = [
  PLAYER_ACTION_TYPES.SET_VOLUME,
  PLAYER_ACTION_TYPES.SET_PLAYBACK_RATE,
  PLAYER_ACTION_TYPES.SET_MEDIA_QUALITY,
  PLAYER_ACTION_TYPES.SET_REWIND,
  ...values(MEDIA_TEXT_SETTINGS_ACTION_TYPES)
];

const readSettingsFromStorage = () => {
  try {
    const mediaPlayerSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_MEDIA_PLAYER_SETTINGS));
    const mediaTextSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_MEDIA_TEXT_SETTINGS));
    return {
      mediaPlayer: {
        player: mediaPlayerSettings || {}
      },
      mediaText: {
        settings: mediaTextSettings || {}
      }
    };
  } catch (error) {
    if (isFunction(window.onerror)) {
      window.onerror(error);
    }
  }
};

const writeSettingsToStorage = (state) => {
  const {
    mediaPlayer: {
      player: {
        volume,
        playbackRate,
        mediaQuality,
        rewind
      }
    },
    mediaText: {
      settings: mediaTextSettings
    }
  } = state;
  const mediaPlayerSettings = {
    volume,
    playbackRate,
    mediaQuality,
    rewind
  };
  localStorage.setItem(LOCAL_STORAGE_KEY_MEDIA_PLAYER_SETTINGS, JSON.stringify(mediaPlayerSettings));
  localStorage.setItem(LOCAL_STORAGE_KEY_MEDIA_TEXT_SETTINGS, JSON.stringify(mediaTextSettings));
};

const SettingsSaverMiddleware = ({ getState }) => (next) => (action) => {
  const result = next(action);
  if (includes(RELATED_ACTION_TYPES, action.type)) {
    const { mediaPlayer, mediaText, onboardingTour } = getState();

    if (!onboardingTour.active) {
      writeSettingsToStorage({ mediaPlayer, mediaText });
    }
  }
  return result;
};

export {
  readSettingsFromStorage,
  SettingsSaverMiddleware
};
