import { actions } from './actions';

import settings from './settings/reducer';

import { findTargetTranslationLanguage } from './constants';

import { embedInitStorage } from '../../utils';

const DEFAULT_LANGUAGE_CODE = 'en'; // TODO use i18n to get current selected language in UI

const translationLanguages = embedInitStorage.translation_languages;

const targetTranslationLanguage = () => {
  if (!translationLanguages) {
    return null;
  }

  const [translationLanguage] = translationLanguages;

  const language = findTargetTranslationLanguage(translationLanguages, DEFAULT_LANGUAGE_CODE);
  if (language) {
    return language;
  }

  return translationLanguage;
};

const initialState = {
  sync_data: null, // object,
  fetchingSyncData: true,
  groupedWordTranslations: {},
  translationLanguages,
  autoscroll: true,
  draggedWord: null,
  targetTranslationLanguage: targetTranslationLanguage()
};

const mediaText = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_SYNC_DATA:
      return { ...state, ...action.payload };
    case actions.SET_FETCHING_SYNC_DATA:
      return { ...state, fetchingSyncData: action.payload };
    case actions.SET_GROUPED_WORD_TRANSLATIONS:
      return { ...state, groupedWordTranslations: action.payload };
    case actions.SET_TRANSLATION_LANGUAGES:
      return { ...state, translationLanguages: action.payload };
    case actions.SET_TARGET_TRANSLATION_LANGUAGE:
      return { ...state, targetTranslationLanguage: action.payload };
    case actions.SET_AUTOSCROLL:
      return { ...state, autoscroll: action.payload };
    case actions.TOGGLE_AUTOSCROLL:
      return { ...state, autoscroll: !state.autoscroll };
    case actions.DRAGGED_WORD_SET: {
      return {
        ...state,
        draggedWord: action.payload
      };
    }
    default:
      return {
        ...state,
        settings: settings(state.settings, action)
      };
  }
};

export default mediaText;
