import { actions } from './actions';
import player from './player/reducer';

import { embedInitStorage } from '../../utils';

const initialState = embedInitStorage.embed_mediaitem || {
  id: null,
  poster: null,
  title: null,
  producer: {}
};

const mediaPlayer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, ...action.payload };
    default:
      return {
        ...state,
        player: player(state.player, action)
      };
  }
};

export default mediaPlayer;
