import React, { useState, Fragment } from 'react';
import { object } from 'prop-types';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Typography from '@material-ui/core/Typography';
import css from './styles.scss';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

const Description = (props) => {
  const { title, description, format_price, small_posters = [], mediaitems = [] } = props.product;

  const [indexSlide, setIndexSlide] = useState(0);

  const onChange = (number) => {
    setIndexSlide(number);
  };

  const slideDescription = () => {
    if (mediaitems.length > 0) {
      return (
        <div className={css.slideDescription}>
          <Typography variant="h6" paragraph>
            { mediaitems[indexSlide].title }
          </Typography>
          <Typography paragraph className={css.message}>
            { mediaitems[indexSlide].description }
          </Typography>
        </div>
      );
    }

    return <div className={css.slideDescription} />;
  };

  return (
    <Fragment>
      <Typography variant="h4" paragraph>
        { title }
      </Typography>
      <Typography variant="h6" paragraph className={css.message}>
        { description }
      </Typography>
      <Typography variant="h3" paragraph className={css.price}>
        { format_price }
      </Typography>

      <Slider {...settings} initialSlide={0} afterChange={onChange}>
        {small_posters.map((poster) => (
          <img key={poster} src={poster} alt={poster} />
        ))}
      </Slider>

      { slideDescription() }
    </Fragment>
  );
};

Description.propTypes = {
  product: object
};

export default Description;
