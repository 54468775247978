import { LOCAL_STORAGE_NAMESPACE } from '../../components/storage/constants';

const FIRST = 'first';
const START_PLAY_CONTENT = 'startPlayContent';
const STOP_PLAY_CONTENT = 'stopPlayContent';
const DRAG_TO_WORD_INFO = 'drag_to_word_info';
const EXPLORE_WORD_INFO = 'explore_word_info';
const DRAG_TO_ADD_TO_WORD_LIST = 'dragToAddToWordList';
const EXPLORE_WORD_LISTS = 'exploreWordLists';
const LAST = 'last';

const STORAGE_KEY = `${LOCAL_STORAGE_NAMESPACE}.onboarding.tour`;

const STEP_NAMES = [
  FIRST,
  START_PLAY_CONTENT,
  STOP_PLAY_CONTENT,
  DRAG_TO_WORD_INFO,
  EXPLORE_WORD_INFO,
  DRAG_TO_ADD_TO_WORD_LIST,
  EXPLORE_WORD_LISTS,
  LAST
];

export {
  STORAGE_KEY,
  STEP_NAMES,
  FIRST,
  START_PLAY_CONTENT,
  STOP_PLAY_CONTENT,
  DRAG_TO_WORD_INFO,
  EXPLORE_WORD_INFO,
  DRAG_TO_ADD_TO_WORD_LIST,
  EXPLORE_WORD_LISTS,
  LAST
};
