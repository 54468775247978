import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';
import screenfull from 'screenfull';

import global from '../global/reducer';
import mediaPlayer from '../components/media-player/reducer';
import mediaText from '../components/media-text/reducer';
import layout from '../components/layout/reducer';
import alertify from '../components/alertify/reducer';
import dialog from '../components/dialog/reducer';
import helpPopup from '../components/help-popup/reducer';
import bigDef from '../components/bigdef/reducer';
import learnerData from '../components/learner-data/reducer';
import authenticationModal from '../components/authentication-modal/reducer';
import monetization from '../components/monetization/reducer';
import commonWordList from '../components/common-word-list/reducer';
import suggestedWordList from '../components/suggested-word-list/reducer';
import settings from '../components/settings/reducer';
import onboardingTour from './onboarding-tour/reducer';
import LearnerDataSaga from '../components/learner-data/sagas';
import helpPopupSaga from '../components/help-popup/sagas';
import { SettingsSaverMiddleware } from '../components/store/settings';
import FullscreenMiddleware from '../components/layout/fullscreen/middleware';
import OnboardingTourMiddleware from './onboarding-tour/middleware';

const reducers = combineReducers({
  global,
  mediaPlayer,
  mediaText,
  layout,
  alertify,
  dialog,
  helpPopup,
  bigDef,
  learnerData,
  authenticationModal,
  monetization,
  commonWordList,
  suggestedWordList,
  settings,
  onboardingTour
});

const sagaMiddleware = createSagaMiddleware();

function setupStore({ publicApi, bigDefApi, authenticationApi, middleware = [] }) {
  const learnerDataSaga = LearnerDataSaga({ publicApi, bigDefApi, authenticationApi });

  const middlewareToUse = [sagaMiddleware, OnboardingTourMiddleware, SettingsSaverMiddleware];

  if (screenfull.isEnabled) {
    middlewareToUse.push(FullscreenMiddleware);
  }

  middlewareToUse.push(...middleware);

  const store = createStore(
    reducers,
    compose(
      reduxBatch,
      applyMiddleware(...middlewareToUse)
    )
  );

  sagaMiddleware.run(learnerDataSaga);
  sagaMiddleware.run(helpPopupSaga);

  return store;
}

export default setupStore;
