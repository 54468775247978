const ACTION_TYPE_PREFIX = 'HELP_POPUP';

const ACTION_TYPES = {
  OPEN: `${ACTION_TYPE_PREFIX}_OPEN`,
  CLOSE: `${ACTION_TYPE_PREFIX}_CLOSE`
};

const open = (contentType) => ({
  type: ACTION_TYPES.OPEN,
  payload: contentType
});

const close = () => ({
  type: ACTION_TYPES.CLOSE
});

const actionCreators = {
  open,
  close
};

export {
  ACTION_TYPES,
  actionCreators
};
