const ACTION_TYPE_PREFIX = 'SUGGESTED_WORD_LIST';

const ACTION_TYPES = {
  OPEN: `${ACTION_TYPE_PREFIX}_OPEN`,
  CLOSE: `${ACTION_TYPE_PREFIX}_CLOSE`
};

const open = () => ({
  type: ACTION_TYPES.OPEN
});

const close = () => ({
  type: ACTION_TYPES.CLOSE
});

const suggestedWordListActions = {
  open, close
};

export { ACTION_TYPES, suggestedWordListActions };
