import { ACTIONS_PREFIX } from '../../constants';

const NAMESPACE = 'MEDIA_TEXT_SETTINGS';

const actions = {
  SET_FONT_SIZE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_FONT_SIZE`,
  SET_TRANSLATION_FONT_SIZE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_TRANSLATION_FONT_SIZE`,
  SET_SCROLL_TO_ALIGNMENT: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SCROLL_TO_ALIGNMENT`,
  SET_SPACE_BETWEEN_FRAGMENTS: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SPACE_BETWEEN_FRAGMENTS`,
  SET_VISIBLE_LINES_NUMBER: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_VISIBLE_LINES_NUMBER`,
  SET_SHOW_SUBTITLES: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SHOW_SUBTITLES`,
  TOGGLE_SHOW_SUBTITLES: `${ACTIONS_PREFIX}_${NAMESPACE}_TOGGLE_SHOW_SUBTITLES`,
  SET_SHOW_FRAGMENT_TRANSLATION: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SHOW_FRAGMENT_TRANSLATION`,
  TOGGLE_SHOW_FRAGMENT_TRANSLATION: `${ACTIONS_PREFIX}_${NAMESPACE}_TOGGLE_SHOW_FRAGMENT_TRANSLATION`,
  SET_SHOW_WORD_TRANSLATION: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SHOW_WORD_TRANSLATION`,
  SET_SHOW_KNOWN_WORDS: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SHOW_KNOWN_WORDS`,
  TOGGLE_SHOW_WORD_TRANSLATIONS: `${ACTIONS_PREFIX}_${NAMESPACE}_TOGGLE_SHOW_WORD_TRANSLATIONS`,
  SET_SHOW_WORD_LIST_WORDS: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_SHOW_WORD_LIST_WORDS`,
  SET_CLICK_RANDOM_ACCESS_MODE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_CLICK_RANDOM_ACCESS_MODE`,
  SET_FRAGMENT_TRANSLATIONS_LOCATION: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_FRAGMENT_TRANSLATIONS_LOCATION`,
  SET_GRAMMAR_HIGHLIGHTS: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_GRAMMAR_HIGHLIGHTS`,
  SET_BACKGROUND_OPACITY: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_BACKGROUND_OPACITY`,
  SET_ONBOARDING_TOUR_MODE: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_ONBOARDING_TOUR_MODE`,
  CANCEL_ONBOARDING_TOUR_MODE: `${ACTIONS_PREFIX}_${NAMESPACE}_CANCEL_ONBOARDING_TOUR_MODE`
};

const setFontSize = (fontSize) => ({
  type: actions.SET_FONT_SIZE,
  payload: fontSize
});

const setTranslationFontSize = (translationFontSize) => ({
  type: actions.SET_TRANSLATION_FONT_SIZE,
  payload: translationFontSize
});

const setScrollToAlignment = (scrollToAlignment) => ({
  type: actions.SET_SCROLL_TO_ALIGNMENT,
  payload: scrollToAlignment
});

const setSpaceBetweenFragments = (spaceBetweenFragments) => ({
  type: actions.SET_SPACE_BETWEEN_FRAGMENTS,
  payload: spaceBetweenFragments
});

const setVisibleLinesNumber = (visibleLinesNumber) => ({
  type: actions.SET_VISIBLE_LINES_NUMBER,
  payload: visibleLinesNumber
});

/**
 * set if Subtitles can be visible
 *
 * @param {Boolean} value.
 * @example:
 *   setShowSubtitles(true)
 */
const setShowSubtitles = (showSubtitles) => ({
  type: actions.SET_SHOW_SUBTITLES,
  payload: showSubtitles
});

const toggleShowSubtitles = () => ({
  type: actions.TOGGLE_SHOW_SUBTITLES
});

/**
 * set if Fragment Translation can be visible
 *
 * @param {Boolean} value.
 * @example:
 *   setShowFragmentTranslation(true)
 */
const setShowFragmentTranslation = (showFragmentTranslation) => ({
  type: actions.SET_SHOW_FRAGMENT_TRANSLATION,
  payload: showFragmentTranslation
});

const toggleShowFragmentTranslation = () => ({
  type: actions.TOGGLE_SHOW_FRAGMENT_TRANSLATION
});

/**
 * set if Word Translation can be caused on mouse hover
 *
 * @param {Boolean} value.
 * @example:
 *   setShowWordTranslation(true)
 */
const setShowWordTranslation = (showWordTranslation) => ({
  type: actions.SET_SHOW_WORD_TRANSLATION,
  payload: showWordTranslation
});

const setShowKnownWords = (showKnownWords) => ({
  type: actions.SET_SHOW_KNOWN_WORDS,
  payload: showKnownWords
});

const toggleShowWordTranslations = () => ({
  type: actions.TOGGLE_SHOW_WORD_TRANSLATIONS
});

const setShowWordListWords = (showWordListWords) => ({
  type: actions.SET_SHOW_WORD_LIST_WORDS,
  payload: showWordListWords
});

/**
 * set if clicking on Word will alternate play/pause
 *
 * @param {Boolean} value.
 * @example:
 *   setClickRandomAccessMode(true)
 */
const setClickRandomAccessMode = (clickRandomAccessMode) => ({
  type: actions.SET_CLICK_RANDOM_ACCESS_MODE,
  payload: clickRandomAccessMode
});

const setFragmentTranslationsLocation = (location) => ({
  type: actions.SET_FRAGMENT_TRANSLATIONS_LOCATION,
  payload: location
});

const setGrammarHighlights = (grammarHighlights) => ({
  type: actions.SET_GRAMMAR_HIGHLIGHTS,
  payload: grammarHighlights
});

const setBackgroundOpacity = (backgroundOpacity) => ({
  type: actions.SET_BACKGROUND_OPACITY,
  payload: backgroundOpacity
});

const setOnboardingTourMode = () => ({
  type: actions.SET_ONBOARDING_TOUR_MODE
});

const cancelOnboardingTourMode = () => ({
  type: actions.CANCEL_ONBOARDING_TOUR_MODE
});

const actionCreators = {
  setFontSize,
  setTranslationFontSize,
  setScrollToAlignment,
  setSpaceBetweenFragments,
  setVisibleLinesNumber,
  setShowSubtitles,
  toggleShowSubtitles,
  setShowFragmentTranslation,
  toggleShowFragmentTranslation,
  setShowWordTranslation,
  setShowKnownWords,
  toggleShowWordTranslations,
  setShowWordListWords,
  setClickRandomAccessMode,
  setFragmentTranslationsLocation,
  setGrammarHighlights,
  setBackgroundOpacity,
  setOnboardingTourMode,
  cancelOnboardingTourMode
};

export { actions, actionCreators };
