import { fork } from 'redux-saga/effects';
import { KnownWordsFetcher } from './known-words';
import { UserWordsFetcher } from './user-words';

import { CurrentUserFetcher } from './current-user';

function LearnerDataSaga(options) {
  const knownWordsFetcher = KnownWordsFetcher(options);
  const currentUserFetcher = CurrentUserFetcher(options);
  const userWordsFetcher = UserWordsFetcher(options);

  return function* () {
    yield fork(knownWordsFetcher);
    yield fork(userWordsFetcher);
    yield fork(currentUserFetcher);
  };
}

export default LearnerDataSaga;
