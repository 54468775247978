import { head } from 'lodash';

const loadLocalesPath = (languages, namespaces) => {
  const language = head(languages);
  const namespace = head(namespaces);

  console.log('language, namespace', language, namespace)

  switch (language) {
    case 'en':
      if (namespace === 'components') {
        return '/assets/en/components-6224ab9ae36d2c5b1b3e9b3707f93126741882194248ef411945eecca29fad86.json';
      }

      if (namespace === 'itl') {
        return '/assets/en/itl-02b05b3433d4d5f6242588e5d32fb1e622a09a596a3a729a7d97e999714fbd77.json';
      }

      if (namespace === 'authentication') {
        return '/assets/en/authentication-14bfffd61f4d63e8d7797d91f82ebc3037c18c39122fad540a019d73371d7792.json';
      }

      return '/assets/en/itl-02b05b3433d4d5f6242588e5d32fb1e622a09a596a3a729a7d97e999714fbd77.json';

    case 'es':
      if (namespace === 'components') {
        return '/assets/es/components-32ebdc77abfb4e97f925636f2629b04d5a660eda69e565896a5d88fa083f351d.json';
      }
      if (namespace === 'itl') {
        return '/assets/es/itl-f971d8e53ed1b7af9280228ccd1dfc247b8c1e1f5ea9660368a4d8396ae98099.json';
      }
      return '/assets/es/itl-f971d8e53ed1b7af9280228ccd1dfc247b8c1e1f5ea9660368a4d8396ae98099.json';

    case 'fr':
      if (namespace === 'components') {
        return '/assets/fr/components-4d139874d8db7af08dbdd95363fa7b016f541da4b030fed195d5237cfe98bf67.json';
      }
      return '/assets/fr/components-4d139874d8db7af08dbdd95363fa7b016f541da4b030fed195d5237cfe98bf67.json';

    case 'pt-PT':
      if (namespace === 'components') {
        return '/assets/pt-PT/components-e56d144ab09bd8a00a6870ee4e4c81269e8c4200b596240115e6eb9243305030.json';
      }
      if (namespace === 'itl') {
        return '/assets/pt-PT/itl-0b063c991e709c234f96685f27fc7f3c851c0e6e049140921ee8e43f298966a3.json';
      }
      return '/assets/pt-PT/itl-0b063c991e709c234f96685f27fc7f3c851c0e6e049140921ee8e43f298966a3.json';

    case 'ru':
      if (namespace === 'components') {
        return '/assets/ru/components-ada219ba55573b0f0dcc4745e9393d67d72d6248bd12272879d0c920f9cc835f.json';
      }
      if (namespace === 'itl') {
        return '/assets/ru/itl-7f2c14a1586b9d4ff218a52231c5c9d3de0df097ab7887672e21cb320e853352.json';
      }
      return '/assets/ru/itl-7f2c14a1586b9d4ff218a52231c5c9d3de0df097ab7887672e21cb320e853352.json';

    case 'tr':
      if (namespace === 'components') {
        return '/assets/tr/components-46f608d8d6d26c4cc88f4baef178e8d60e9f02ba3be31980a65f6e6a76abf85a.json';
      }
      if (namespace === 'itl') {
        return '/assets/tr/itl-8d4387566bd4fbcf6271e818e582028140abab049287c5d382c71ca1bee97d04.json';
      }
      return '/assets/tr/itl-8d4387566bd4fbcf6271e818e582028140abab049287c5d382c71ca1bee97d04.json';

    case 'ar':
      if (namespace === 'components') {
        return '/assets/ar/components-4fad6ead0bfc9d6d60bd0f2f24bd98a9aa72b88c3afb913776714afab4b09226.json';
      }
      if (namespace === 'itl') {
        return '/assets/ar/itl-5099bf4f1c688644c810f43d50b04f1b3f34cafef73bd51ebf534d9d3baf4987.json';
      }
      return '/assets/ar/itl-5099bf4f1c688644c810f43d50b04f1b3f34cafef73bd51ebf534d9d3baf4987.json';

    default:
      return '/assets/en/itl-02b05b3433d4d5f6242588e5d32fb1e622a09a596a3a729a7d97e999714fbd77.json';
  }
};

export default loadLocalesPath;
