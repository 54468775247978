import { ACTION_TYPES } from './actions';

const SETTING_SECTIONS = {
  player: 'player',
  transcript: 'transcript',
  shortcuts: 'shortcuts',
  wordInfo: 'wordInfo',
  wordList: 'wordList'
};

const initialState = {
  section: SETTING_SECTIONS.transcript
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SECTION:
      return { ...state, section: action.payload };
    default:
      return state;
  }
};

export { SETTING_SECTIONS };

export default settingsReducer;
