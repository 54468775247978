import React, { Fragment, useRef, useState, useEffect } from 'react';
import { object, bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AuthenticationModal from 'alp-shared-components/dist/authentication';
import { ApiProvider as AuthenticationApiProvider } from 'alp-shared-components/dist/authentication/providers/api';
import { forms } from 'alp-shared-components/dist/authentication/constants';

import { authenticationModalActions } from './actions';
import { actionCreators as learnerDataActionCreators } from '../learner-data/actions';
import withAuthenticationAPI from '../../api/context-authentication';

const LOGIN_WINDOW_WIDTH = 400;
const LOGIN_WINDOW_HEIGHT = 600;

const getWindowFeatures = () => {
  const { innerWidth, innerHeight } = window;
  const left = (innerWidth / 2) - (LOGIN_WINDOW_WIDTH / 2);
  const top = (innerHeight / 2) - (LOGIN_WINDOW_HEIGHT / 2);
  return `left=${left},top=${top},width=${LOGIN_WINDOW_WIDTH},height=${LOGIN_WINDOW_HEIGHT}`;
};

const Provider = (props) => {
  const {
    children,
    isAuthenticationModalOpen,
    closeAuthenticationModal,
    setCurrentUser,
    authenticationApi,
    reason
  } = props;

  const requestStorageAccessWindow = useRef();
  const [form, setForm] = useState(forms.LOGIN_FORM);

  useEffect(() => {
    if (isAuthenticationModalOpen) {
      checkRequestStorageAccess();
    }

    return () => {
      requestStorageAccessWindow.current?.removeEventListener('message', onMessage, false);
    };
  }, [isAuthenticationModalOpen]);

  const onMessage = (event) => {
    const { origin, data } = event;

    if (origin !== process.env.FULL_URL) {
      return;
    }

    if (data.type === 'acceptCookies') {
      acceptCookies();
    }
  };

  const acceptCookies = () => {
    if (!document.requestStorageAccess) {
      return;
    }

    const promise = document.requestStorageAccess();
    promise.then(() => {
      requestStorageAccessWindow.current?.close();
      setForm(forms.LOGIN_FORM);
    },
    () => {
      requestStorageAccessWindow.current?.close();
    });
  };

  const checkRequestStorageAccess = () => {
    if (!document.requestStorageAccess) {
      return;
    }

    window.addEventListener('message', onMessage, false);

    const promise = document.requestStorageAccess();
    promise.then(() => {
      setForm(forms.LOGIN_FORM);
      console.log('Storage access was granted.');
    },
    () => {
      setForm(forms.ACCEPT_COOKIES);
      console.log('Storage access was denied.');
    });
  };

  const handleLogin = (user) => (
    authenticationApi.login(user).then((data) => {
      window.parent.postMessage({ type: 'login', payload: data }, '*');
      closeAuthenticationModal();
      setCurrentUser(data);

      return data;
    })
  );

  const handleJoin = (user, reCaptchaToken) => {
    user.join_referrer = document.referrer;

    return authenticationApi.join(user, reCaptchaToken).then((data) => {
      window.parent.postMessage({ type: 'login', payload: data }, '*');
      setCurrentUser(data);
      return data;
    });
  };

  const sendResetPasswordInstructions = (user) => (
    authenticationApi.sendResetPasswordInstructions(user).then((data) => data)
  );

  const socialNetworkAuthenticate = (user, socialAccount) => {
    user.join_referrer = document.referrer;

    return authenticationApi.socialNetworkAuthenticate(user, socialAccount).then((data) => {
      window.parent.postMessage({ type: 'login', payload: data }, '*');

      closeAuthenticationModal();
      setCurrentUser(data);
      return data;
    });
  };

  const openFirstPartyWindow = () => {
    requestStorageAccessWindow.current = window.open(
      `${process.env.FULL_URL}/sdk/request_storage_access`,
      `${process.env.REACT_APP_PROJECT_NAME} accept cookies`,
      getWindowFeatures()
    );
  };

  return (
    <Fragment>
      { children }

      <AuthenticationApiProvider
        login={handleLogin}
        join={handleJoin}
        sendResetPasswordInstructions={sendResetPasswordInstructions}
        socialNetworkAuthenticate={socialNetworkAuthenticate}
      >
        <AuthenticationModal
          isOpen={isAuthenticationModalOpen}
          onClose={closeAuthenticationModal}
          openFirstPartyWindow={openFirstPartyWindow}
          form={form}
          reason={reason}
        />
      </AuthenticationApiProvider>
    </Fragment>
  );
};

const mapStateToProps = ({ authenticationModal }) => ({
  isAuthenticationModalOpen: authenticationModal.open,
  reason: authenticationModal.reason
});

const mapDispatchToProps = {
  closeAuthenticationModal: authenticationModalActions.closeAuthenticationModal,
  setCurrentUser: learnerDataActionCreators.setCurrentUser
};

Provider.propTypes = {
  children: object,
  isAuthenticationModalOpen: bool,
  closeAuthenticationModal: func,
  setCurrentUser: func,
  authenticationApi: object,
  reason: string
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticationAPI(Provider));
