const ACTION_TYPE_PREFIX = 'ONBOARDING_TOUR';

const ACTION_TYPES = {
  START: `${ACTION_TYPE_PREFIX}_START`,
  FINISH: `${ACTION_TYPE_PREFIX}_FINISH`,
  SET_ACTIVE: `${ACTION_TYPE_PREFIX}_SET_ACTIVE`,
  SET_RUN: `${ACTION_TYPE_PREFIX}_SET_RUN`,
  SET_STEP_INDEX: `${ACTION_TYPE_PREFIX}_SET_STEP_INDEX`,
  CONTINUE_TOUR: `${ACTION_TYPE_PREFIX}_CONTINUE_TOUR`
};

const start = (steps) => ({
  type: ACTION_TYPES.START,
  steps
});

const finish = () => ({
  type: ACTION_TYPES.FINISH
});

const continueTour = () => ({
  type: ACTION_TYPES.CONTINUE_TOUR
});

const setActive = (active) => ({
  type: ACTION_TYPES.SET_ACTIVE,
  payload: active
});

const setRun = (run) => ({
  type: ACTION_TYPES.SET_RUN,
  payload: run
});

const setStepIndex = (stepIndex) => ({
  type: ACTION_TYPES.SET_STEP_INDEX,
  payload: stepIndex
});

const onboardingTourActions = {
  start,
  finish,
  continueTour,
  setRun,
  setStepIndex,
  setActive
};

export { ACTION_TYPES, onboardingTourActions };
