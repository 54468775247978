import { flatten } from 'lodash';
import { createSelector } from 'reselect';

const getSyncData = ({ mediaText }) => mediaText.sync_data;
const getPlayedTime = ({ mediaPlayer }) => mediaPlayer.player.playedTime;

const findCurrentWord = createSelector(
  getSyncData,
  getPlayedTime,
  (syncData, playedTime) => getCurrentWord(syncData, playedTime)
);

const getCurrentWord = (syncData, playedTime) => {
  const sync = flatten(syncData);

  let currentWord = null;

  sync.every((word) => {
    if (playedTime >= Math.floor(word.time * 1000)) {
      currentWord = word;
      return true;
    }

    return false;
  });

  return currentWord;
};

export { findCurrentWord };
