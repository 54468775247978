const SIDE_PANE_SECTIONS = {
  BIG_DEF: 'bigdef',
  WORD_LISTS: 'wordLists'
};

const MODES = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait'
};

const isLandscapeMode = (mode) => mode === MODES.LANDSCAPE;
const isPortraitMode = (mode) => mode === MODES.PORTRAIT;

export { SIDE_PANE_SECTIONS, MODES, isLandscapeMode, isPortraitMode };
