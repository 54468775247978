const ACTION_TYPE_PREFIX = 'LEARNER_DATA';

const ACTION_TYPES = {
  KNOWN_WORDS_ADD: `${ACTION_TYPE_PREFIX}_KNOWN_WORDS_ADD`,
  KNOWN_WORDS_REMOVE: `${ACTION_TYPE_PREFIX}_KNOWN_WORDS_REMOVE`,
  KNOWN_WORDS_SET: `${ACTION_TYPE_PREFIX}_KNOWN_WORDS_SET`,
  WORD_LIST_WORDS_SET: `${ACTION_TYPE_PREFIX}_WORD_LIST_WORDS_SET`,
  WORD_LIST_WORDS_ADD: `${ACTION_TYPE_PREFIX}_WORD_LIST_WORDS_ADD`,
  WORD_LIST_WORDS_REMOVE: `${ACTION_TYPE_PREFIX}_WORD_LIST_WORDS_REMOVE`,

  FETCH_CURRENT_USER_SET: `${ACTION_TYPE_PREFIX}_FETCH_CURRENT_USER_SET`,
  CURRENT_USER_SET: `${ACTION_TYPE_PREFIX}CURRENT_USER_SET`
};

const setKnownWords = (knownWords) => ({
  type: ACTION_TYPES.KNOWN_WORDS_SET,
  payload: knownWords
});

const setWordListWords = (wordListWords) => ({
  type: ACTION_TYPES.WORD_LIST_WORDS_SET,
  payload: wordListWords
});

const addWordListWord = (dictionaryWordIds) => ({
  type: ACTION_TYPES.WORD_LIST_WORDS_ADD,
  payload: dictionaryWordIds
});

const removeWordListWord = (dictionaryWordIds) => ({
  type: ACTION_TYPES.WORD_LIST_WORDS_REMOVE,
  payload: dictionaryWordIds
});

const addKnownWord = (dictionaryWordIds) => ({
  type: ACTION_TYPES.KNOWN_WORDS_ADD,
  payload: dictionaryWordIds
});

const removeKnownWord = (dictionaryWordIds) => ({
  type: ACTION_TYPES.KNOWN_WORDS_REMOVE,
  payload: dictionaryWordIds
});

const fetchCurrentUser = () => ({
  type: ACTION_TYPES.FETCH_CURRENT_USER_SET
});

const setCurrentUser = (currentUser) => ({
  type: ACTION_TYPES.CURRENT_USER_SET,
  payload: currentUser
});

const actionCreators = {
  addKnownWord,
  removeKnownWord,
  setKnownWords,
  setWordListWords,
  addWordListWord,
  removeWordListWord,
  setCurrentUser,
  fetchCurrentUser
};

export { ACTION_TYPES, actionCreators };
