import { ACTIONS_PREFIX } from '../constants';

const NAMESPACE = 'MEDIA_PLAYER';

const actions = {
  SET_DATA: `${ACTIONS_PREFIX}_${NAMESPACE}_SET_DATA`
};

const setData = (data) => ({
  type: actions.SET_DATA,
  payload: data
});

const actionCreators = {
  setData
};

export { actions, actionCreators };
