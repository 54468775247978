import { ACTION_TYPES } from './actions';

const initialState = {
  active: false,
  run: false,
  stepIndex: 0,
  steps: []
};

const onbooardingTourReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.START:
      return { ...state, active: true, run: true, steps: action.steps };
    case ACTION_TYPES.FINISH:
      return { ...state, active: false, run: false };
    case ACTION_TYPES.CONTINUE_TOUR:
      return { ...state, run: true, stepIndex: state.stepIndex + 1 };
    case ACTION_TYPES.SET_RUN:
      return { ...state, run: action.payload };
    case ACTION_TYPES.SET_STEP_INDEX:
      return { ...state, stepIndex: action.payload };
    case ACTION_TYPES.SET_ACTIVE:
      return { ...state, active: action.payload };
    default:
      return state;
  }
};

export default onbooardingTourReducer;
