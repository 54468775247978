const NAMESPACE = 'MEDIA_PLAYER_PLAYLIST';

const actions = {
  OPEN: `${NAMESPACE}_OPEN`,
  CLOSE: `${NAMESPACE}_CLOSE`,
  SET_GROUP: `${NAMESPACE}_SET_GROUP`
};

const open = () => ({
  type: actions.OPEN
});

const close = () => ({
  type: actions.CLOSE
});

const setGroup = (group) => ({
  type: actions.SET_GROUP,
  payload: group
});

const playlistActions = {
  open, close, setGroup
};

export { actions, playlistActions };
