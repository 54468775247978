const ACTION_TYPE_PREFIX = 'COMMON_WORD_LIST';

const ACTION_TYPES = {
  SET_WORD_LIST: `${ACTION_TYPE_PREFIX}_SET_WORD_LIST`
};

const setWordList = (wordList) => ({
  type: ACTION_TYPES.SET_WORD_LIST,
  payload: wordList
});

const commonWordListActions = {
  setWordList
};

export { ACTION_TYPES, commonWordListActions };
