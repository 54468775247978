import { difference } from 'lodash';
import { ACTION_TYPES } from './actions';

import { embedInitStorage } from '../../utils';

const INITIAL_STATE = {
  knownWords: embedInitStorage.known_word_ids,
  wordListWords: embedInitStorage.word_lists_word_ids,
  currentUser: embedInitStorage.current_user || {}
};

function learnerData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.KNOWN_WORDS_SET: {
      return {
        ...state,
        knownWords: action.payload
      };
    }
    case ACTION_TYPES.WORD_LIST_WORDS_SET: {
      return {
        ...state,
        wordListWords: action.payload
      };
    }
    case ACTION_TYPES.WORD_LIST_WORDS_ADD: {
      return {
        ...state,
        wordListWords: [...action.payload, ...state.wordListWords]
      };
    }
    case ACTION_TYPES.WORD_LIST_WORDS_REMOVE: {
      const newWordListWords = difference(state.wordListWords, action.payload);
      return {
        ...state,
        wordListWords: newWordListWords
      };
    }
    case ACTION_TYPES.KNOWN_WORDS_ADD: {
      return {
        ...state,
        knownWords: [...action.payload, ...state.knownWords]
      };
    }
    case ACTION_TYPES.KNOWN_WORDS_REMOVE: {
      const newKnownWords = difference(state.knownWords, action.payload);
      return {
        ...state,
        knownWords: newKnownWords
      };
    }
    case ACTION_TYPES.CURRENT_USER_SET: {
      return {
        ...state,
        currentUser: action.payload || {}
      };
    }
  }
  return state;
}

export default learnerData;
