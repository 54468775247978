import { ACTION_TYPES } from './actions';

const INITIAL_STATE = {
  id: null,
  title: '',
  common_word_text: ''
};

const commonWordList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_WORD_LIST: {
      return {
        ...state, ...action.payload
      };
    }
    default:
      return state;
  }
};

export default commonWordList;
