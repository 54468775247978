const actionTypes = {
  OPEN_LOGIN_WINDOW: 'OPEN_LOGIN_WINDOW'
};

const openLoginWindow = (loginWindowType) => ({
  type: actionTypes.OPEN_LOGIN_WINDOW,
  payload: loginWindowType
});

const dismissLoginWindow = () => ({
  type: actionTypes.OPEN_LOGIN_WINDOW,
  payload: null
});

const actionCreators = {
  openLoginWindow,
  dismissLoginWindow
};

export {
  actionTypes,
  actionCreators
};
