import { call, put } from 'redux-saga/effects';
import { forOwn } from 'lodash';
import { actionCreators as alertifyActions } from '../../../components/alertify/actions';

const isNetworkError = (error) => error.request && !error.response;
const isAuthorizationError = (error) => error.response && error.response.status === 401;
const isServerError = (error) => error.response && error.response.status >= 400;

const extractValidationMessages = (errors) => {
  const keys = [];

  forOwn(errors, (value, key) => {
    keys.push(`validations.errorMessages.errors.${key}.${value}`);
  });

  return keys[0];
};

const noop = () => {};

function handleServerError(error) {
  let text = null;

  if (error.response.data.errors) {
    text = extractValidationMessages(error.response.data.errors);
  }

  return alertifyActions.openAlertify({ type: 'error', text: text || error.response.data.message });
}

function Requester(errorHandlers = {}) {
  const {
    network: handleNetworkError = noop,
    auth: handleAuthorizationError = noop
    // server: handleServerError = noop
  } = errorHandlers;

  return function* (request, ...args) {
    try {
      return yield call(request, ...args);
    } catch (error) {
      if (isNetworkError(error)) {
        return handleNetworkError(error);
      }
      if (isAuthorizationError(error)) {
        handleAuthorizationError(error);
      }
      if (isServerError(error)) {
        yield put(handleServerError(error));
      }
      throw error;
    }
  };
}

const safeRequest = Requester();

export default Requester;

export { safeRequest, isAuthorizationError, isServerError };
